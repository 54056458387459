// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `angular-cli.json`.

export const environment = {
  production: false,
  SupportEmailId: 'digital.support@diversey.com',
  MAPS_API_URL: 'https://maps.googleapis.com/maps/api/geocode/json?address=',
  MAPS_TIMEZONE_API_URL: 'https://maps.googleapis.com/maps/api/timezone/json?location=',
  MAPS_TIMEZONE_API_KEY: 'AIzaSyAR2zqTfKGaUCkvELCYOiOAPWxJkUDFESg',
  NODE_URL: 'https://uat-pac.diverseyiot.com/api/v1/',
//  NODE_URL: 'https://pac-pacuat-alb-79248253.eu-west-1.elb.amazonaws.com/api/v1/',
  COOKIE_DOMAIN_NAME: '.diverseyiot.com',
  OKTA_COOKIE_DOMAIN_NAME: 'uat-dashboard.diverseyiot.com',
  COOKIE_PREFIX: 'preprod_',
  DASHBOARD_HOST_URL: 'https://uat-dashboard.diverseyiot.com/',
  PAC_HOST_URL: 'https://uat-pac.diverseyiot.com/',
  TERMS_CONDITIONS: 'https://www.diversey.com/ioc-terms-and-conditions',
  DOMAIN_PREFIX: 'uat-',
  OKTA_DOMAIN: 'https://diversey.okta.com',
  OKTA_ISSUER: 'https://diversey.okta.com',
  OKTA_CLIENT_ID: '0oao8pnyovtAyMM7W357'
};
